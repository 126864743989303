var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.selectedProgram)?_c('v-card',{staticClass:"run-program-payment-container",attrs:{"flat":"","min-width":"350"}},[_c('v-card-title',[(_vm.showRefreshButton)?_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.getPaymentIntents}},[_c('refresh-icon',{attrs:{"stroke":"var(--v-primary-base)"}})],1):_vm._e(),_vm._v(" Payments:- "+_vm._s(_vm.selectedProgram.name)+" ")],1),(_vm.isDesktop)?_c('v-card-text',[_c('v-data-table',{attrs:{"show-select":"","loading":_vm.isLoading || (_vm.programTransaction && _vm.programTransaction.isRunning),"headers":_vm.headers,"item-key":"itemId","items":_vm.items,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"ripple":false,"value":isSelected && !(item && item.disabled),"readonly":item && item.disabled,"disabled":item && item.disabled},on:{"input":function($event){return select($event)}}})]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('editable-amount',{attrs:{"item":item,"amountConfMap":_vm.amountEditors},on:{"updated":_vm.changeAmount}})]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [(item)?_c('span',[_vm._v(_vm._s(item.discount)+" % ")]):_c('span',[_vm._v(" ---- ")])]}},{key:"item.paymentMethod",fn:function(ref){
var item = ref.item;
return [_c('table-payment-method',{attrs:{"isPaymentMethodLoad":_vm.paymentMethodsLoaded,"item":item,"defaultPaymentMethod":item ? item.defaultPaymentMethod : null}})]}},{key:"item.paidInCash",fn:function(ref){
var item = ref.item;
return [_c('cash-payment-button',{attrs:{"disabled":_vm.isLoading || (_vm.programTransaction && _vm.programTransaction.isRunning),"item":item},on:{"cash-click":_vm.changeCashStatus}})]}},{key:"item.paymentDateShort",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(item ? item.paymentDateShort : "----")+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item && item.paymentDate)+" ")])])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('payment-status',{attrs:{"item":item}})]}},{key:"footer.prepend",fn:function(){return [_c('div',{staticClass:"d-flex justify-center align-center gap-2"},[_c('v-btn',{staticClass:"ml-3",attrs:{"disabled":!_vm.showRunButton || _vm.isLoading || _vm.selectedPlayers.length === 0,"outlined":"","color":"primary"},on:{"click":_vm.runPayment}},[_vm._v(" Run Payment ("+_vm._s(_vm.allowedSelectedPlayers.length)+") ")]),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Close ")])],1)]},proxy:true}],null,false,2930823084),model:{value:(_vm.selectedPlayers),callback:function ($$v) {_vm.selectedPlayers=$$v},expression:"selectedPlayers"}})],1):_c('v-card-text',[(_vm.isLoading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),_vm._l((_vm.items),function(item,i){return _c('program-mobile-payment-item',{key:i,attrs:{"item":item,"isParentLoading":_vm.isLoading,"paymentMethodsLoaded":_vm.paymentMethodsLoaded},on:{"amountUpdated":_vm.changeAmount,"changeCashStatus":_vm.changeCashStatus,"runPayment":_vm.runIndividualPayment}})})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }